<template>
	<v-app>
		<v-navigation-drawer class="side"
			:width="440"
			app
			v-model="drawer"
			clipped
		>
			<v-container>
				<!-- <v-list-item>
					<v-list-item-content>
						<v-list-item-title class="title grey--text text--darken-2">
							ため池一覧
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item> -->
				<div class="global-menu">
					<v-btn class="map" 
						@click.stop="showMap" 
						v-bind:class="{ selected:checkCurrentMenu('Home') }"
						>地図</v-btn>
					<v-btn class="data" 
						@click.stop="showData"
						v-bind:class="{ selected:checkCurrentMenu('Place') }"
						>データ</v-btn>
					<v-btn class="photos" 
						@click.stop="showPhotos"
						v-bind:class="{ selected:checkCurrentMenu('Photos') }"
						>最新画像一覧</v-btn>
				</div>
				<div class="items">
					<div class="item header">
						<div>管理番号</div>
						<div>名称</div>
						<div>水位</div>
						<div></div>
					</div>
					<div class="item" style="cursor: pointer;"
						v-for="(item, index) in summaryData"
						:key="item.id"
						@click="clickRow(item)"
						v-bind:class="[
							index == $route.params.id ? 'selected' : 'none',
						]"
					>
						<div>{{ item.id }}</div>
						<div>{{ item.name }}</div>
						<div>{{ waterLevelFormat( current_waterlevels[index] ) }}</div>
						<div class="color-box"
							:style="[
								{
									background: alertColor(item) ,
								},
							]"
						></div>
					</div>
				</div>
			</v-container>
		</v-navigation-drawer>
		<v-app-bar color="grey darken-3" dark app clipped-left dense flat>
			<v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
			<v-toolbar-title style="cursor: pointer" @click="$router.push('/')"
				>PRISM</v-toolbar-title
			>
			<v-spacer></v-spacer>
			<v-toolbar-items style="cursor: pointer">
				<v-menu offset-y>
					<template v-slot:activator="{ on }">
						<v-avatar v-on="on" size="36" style="margin-top: 5px;">
							<img :src="login.image" :alt="login.name" />
						</v-avatar>
					</template>
					<v-list>
						<v-list-item
							link
							@click="$router.push('/home').catch(()=>{});"
						>
							<v-list-item-icon style="margin-right: 10px">
								<v-icon>{{ "mdi-home" }}</v-icon>
							</v-list-item-icon>
							<v-list-item-content style="margin-right: 10px">
								<v-list-item-title>ホーム</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item
							v-show="login.role == 'admin'" class="admin"
							link
							@click.stop="$router.push('/admin')"
						>
							<v-list-item-icon style="margin-right: 10px">
								<v-icon>{{ "mdi-account-supervisor" }}</v-icon>
							</v-list-item-icon>
							<v-list-item-content style="margin-right: 10px">
								<v-list-item-title>管理画面</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<!-- <v-subheader>マイアカウント</v-subheader> -->
						<!--
						<v-list-item link @click.stop="$router.push('/mypage')">
							<v-list-item-icon style="margin-right: 10px">
								<v-icon>{{ "mdi-account" }}</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>アカウント</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						-->
						<v-list-item link @click.stop="logoutDialog = true">
							<v-list-item-icon style="margin-right: 10px">
								<v-icon>{{ "mdi-logout" }}</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>ログアウト</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list>
				</v-menu>
			</v-toolbar-items>
		</v-app-bar>
		<v-main>
			<router-view :key="$route.fullPath" />
		</v-main>
		<v-footer color="grey darken-3" dark app>
			<v-spacer></v-spacer>
			©2023
			<a href="https://www.vegetalia.co.jp/">vegetalia</a>, inc.
		</v-footer>
		<!-- modal -->
		<div class="text-center">
			<v-dialog v-model="logoutDialog" width="500">
				<v-card>
					<v-card-title class="headline"> マイアカウント </v-card-title>
					<v-card-text>ログアウトしますか？</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="green darken-1" text @click="logoutDialog = false">
							いいえ
						</v-btn>
						<v-btn color="green darken-1" text @click="logout"> はい </v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</div>
		<!-- modal END-->
	</v-app>
</template>

<script>
import { mapState } from "vuex";

export default {
	computed: {
		alertColor() {
			return function (item) {
				return this.$store.getters.getAlertColor(item).color;
			};
		}
		, ...mapState("places", ["summaryData"])
		
	},
	data() {
		return {
			drawer: null,
			logoutDialog: false,
			pwchangeDialog: false,
			current_waterlevels: {},
			login: {
				name: "unknown",
				role: "admin",
				image: "/img/noimage.png"
			},
		};
	},
	created() {
		// const current_id = this.$router.history.current.params.id
		// for (const key of Object.keys(this.$store.state.places.summaryData)) {
		for (let i = 0; i < this.$store.state.places.summaryData.length; i++) {
			let summary = this.summaryData[i];
			if (Object.keys(summary.waterlevel).length != 0) {
				this.current_waterlevels[i] = summary.waterlevel.level[summary.waterlevel.level.length-1];
			} else {
				this.current_waterlevels[i] = 0;
			}
		}
	},
	mounted() {
		this.scrollTop();
	},
	methods: {
		scrollTop: function () {
			window.scrollTo({
				top: 0,
				behavior: "smooth",
			});
		},
		clickRow: function(it) {
			let index = this.$store.getters.getPlaceIndexById(it.id);
			this.$router
				.push({ name: "Place", params: { id: index, item: it } })
				.catch(() => {});
		},
		showMap: function() {
			this.$router
				.push({ name: "Home", params: {} })
				.catch(() => {});
		},
		showData: function() {
			const current_id = this.$router.history.current.params.id;
			let current_item = false;
			let count = 0;
			let first_item = false;
			// for (const key of Object.keys(this.$store.state.places.summaryData)) {
			for (let i = 0; i < this.$store.state.places.summaryData.length; i++) {
				let item = this.summaryData[i];
				if (count == 0) {
					first_item = item;
				}
				if(i == current_id){
					current_item = item;
				}
				count++;
			}
			if(current_item){
				let index = this.$store.getters.getPlaceIndexById(current_item.id);
				this.$router
					.push({ name: "Place", params: { id: index, item: current_item } })
					.catch(() => {});
			} else {
				let index = this.$store.getters.getPlaceIndexById(first_item.id);
				this.$router
					.push({ name: "Place", params: { id: index, item: first_item } })
					.catch(() => {});
			}
		},
		showPhotos: function() {
			this.$router
				.push({ name: "Photos", params: {} })
				.catch(() => {});
		},
		checkCurrentMenu: function(n){
			const cn = this.$router.history.current.name;
			if( n == 'Place'  && (cn == 'Place' || cn == 'Forecast' || cn == 'PreDischarge' || cn == 'LowLevel' || cn == 'FloodControl')){
				return true
			} else if( n == 'Home'  && (cn == 'Home')){
				return true
			} else if( n == 'Photos'  && (cn == 'Photos')){
				return true
			}
			return false
		}
		,logout: function() {
			this.$router
				.push({ name: "Login", params: {} })
				.catch(() => {});
			this.logoutDialog = false;
		}
		, plusminus(v) {
			let pms = "+";
			if (v < 0) pms = "";
			return  pms+v
		},
	},
};
</script>
<style scoped lang="scss">
.side { 
	background-color: #DBDBDB; 
	.global-menu {
		background-color: #C1C1C1; padding: 10px; display: flex; justify-content: space-between;
		button { 
			width: calc( 34% - 10px ); height: 100px; margin: 0 10px 0 0 ; padding: 10px 0 0 0; display: flex; flex-direction: column; align-items: center; justify-content: center;
			font-weight: bold; font-size: 18px; border-radius: 20px; 
			&:before {
				background-position: 50% 50%; background-repeat:no-repeat; background-size: contain; content:''; display: inline-block; width: 100%; height: 60%; position: static;
				opacity:1; background-color: transparent; border-radius: 0; 
			}
			&.map:before { background-image:url('~@/assets/icon_map.svg'); width: 40%;  }
			&.data:before { background-image:url('~@/assets/icon_data.svg'); width: 40%; }
			&.photos:before { background-image:url('~@/assets/icon_photos.svg'); width: 35%;  }

			&:last-child { margin:0; }
			&.selected {
				background-color: #fff; border: 4px solid #41D526; 
			}
			@media screen and (max-width:440px){
				font-size: 14px;
			}
		}
	}
	.items {
		.item { 
			border-radius: 10px;  padding: .3em .5em; margin: 8px 10px; display: flex; justify-content: space-between; align-items: center; background-color: #F4F4F4; 
			> div { font-size: 20px; padding: 0 .5em; }
			> div:first-child { width:30%; }
			> div:nth-child(2) { width:40%; }
			> div:nth-child(3) { width:26%; }
			> div:nth-child(4) { width:4%; }
			.color-box { width: 18px; height: 18px; border-radius: 6px; }
			&.selected {
				background-color: #fff; border: 4px solid #41D526;
			}
			&.header {
				background-color: #ddd;
				> div { font-size:12px; }
			}
		}
	}
	
}
</style>